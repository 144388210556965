


























































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      loaded: false,
      dialog: false,
      privacyPolicy: [],
    });

    const model = reactive({
      privacyPolicy: "",
      privacyPolicyContent: "",
      englishPrivacyPolicy: "",
      englishPrivacyPolicyContent: "",
    });

    const fetchConsents = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("consent")
        .then(({ data: { consents } }) => {
          state.privacyPolicy = consents.filter(
            (obj: any) => obj.type == "privacy policy"
          );
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchConsents);

    const fetchSingle = (id: string, language: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`consent/${id}`)
        .then(({ data: { consent } }) => {
          if (language === "polish") {
            model.privacyPolicyContent = consent.template;
          } else model.englishPrivacyPolicyContent = consent.template;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    watch(
      () => model.privacyPolicy,
      () => fetchSingle(model.privacyPolicy, "polish")
    );
    watch(
      () => model.englishPrivacyPolicy,
      () => fetchSingle(model.englishPrivacyPolicy, "english")
    );

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          model.privacyPolicyContent = event.privacyPolicyTemplate;
          model.englishPrivacyPolicyContent =
            event.englishPrivacyPolicyTemplate;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        privacyPolicyTemplate: model.privacyPolicyContent,
        englishPrivacyPolicyTemplate: model.englishPrivacyPolicyContent,
      };

      state.loading = true;

      axiosInstance
        .put(`/event/${root.$route.params.id}/consent`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.templateAlreadyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return { state, model, onSubmit, getErrorMessage };
  },
});
